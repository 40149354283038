<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="forms"
      :rtl="direction"
      :bordered="false"
      class="projects-table"
      styleClass="vgt-table"
    >
      <div slot="emptystate" class="text-center">
        Нет лид-форм
      </div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Shows -->
        <span v-else-if="props.column.field === 'answers'">
          <span
            class="text-nowrap text-primary cursor-pointer"
            @click="
              $router.push({
                name: 'admin-answers',
                query: { leadForm: props.row.id },
              })
            "
          >
            {{ props.row.answers }}
            <feather-icon icon="EyeIcon" class="mr-50" />
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-around">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50 cursor-pointer"
              size="18"
              @click="$emit('editLeadForm', props.row)"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueGoodTable,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: ["forms"],
  data() {
    return {
      dir: false,
      rows: [],
      columns: [
        {
          label: "ID",
          field: "displayId",
        },
        {
          label: "Автор",
          field: "email",
        },
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Заявки",
          field: "answers",
        },
        {
          label: "Действия",
          field: "action",
        },
      ],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>
