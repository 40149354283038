<template>
  <div>
    <div class="custom-search mb-2">
      <b-row>
        <b-col md="4">
          <label for="lead-type"> Выберите пользователя: </label>
          <b-form-select
            id="lead-type"
            :options="usersList"
            v-model="filters.user"
            @change="filterByUser"
          >
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <lead-forms-table :forms="getLeadFormsList" @editLeadForm="editLeadForm" />
    <b-modal
      ref="modal-create"
      cancel-variant="outline-secondary"
      ok-title="Сохранить"
      cancel-title="Закрыть"
      centered
      title="Редактировать форму"
      @ok="
        editItem({
          name: createFormName,
          questions: questionsList,
          id: editFormId,
          sucсessText: createFormSuccess,
          title: createFormTitle,
        })
      "
      @hide="resetModal"
    >
      <b-form>
        <b-form-group>
          <label for="name">Название:</label>
          <b-form-input
            id="name"
            placeholder="Введите название"
            v-model="createFormName"
          />
        </b-form-group>
        <b-form-group>
          <label for="name">Заголовок Лид Формы:</label>
          <b-form-input
            id="name"
            placeholder="Введите заголовок"
            v-model="createFormTitle"
          />
        </b-form-group>
        <label> Поля: </label>
        <draggable
          v-model="questionsList"
          class="list-group list-group-flush cursor-move"
          tag="ul"
        >
          <transition-group type="transition" name="flip-list">
            <b-list-group-item
              class="d-flex"
              v-for="question in questionsList"
              :key="question.id"
              tag="li"
            >
              <b-row class="w-2/6">
                <b-col>
                  <label for="question-type"> Тип </label>
                  <b-form-select
                    id="question-type"
                    v-model="question.type"
                    :options="questionOptionsList"
                    :disabled="true"
                  >
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row class="w-2/6">
                <b-col>
                  <label for="question-title"> Название поля </label>
                  <b-form-input
                    id="question-title"
                    v-model="question.title"
                    placeholder="Введите название"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="w-1/6">
                <b-col> </b-col>
              </b-row>
              <b-row class="w-1/6">
                <b-col class="w-100">
                  <label for="question-enabled">Активно</label>
                  <b-form-checkbox
                    v-model="question.enabled"
                    style="margin-top: 0.5rem; margin-left: 1rem !important; width: fit-content;"
                  >
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-list-group-item>
          </transition-group>
        </draggable>
        <b-form-group class="mt-2">
          <label for="name">Текст после отправки формы:</label>
          {{ createFormSuccess }}
          <b-form-input
            id="name"
            placeholder="Введите сообщение"
            v-model="createFormSuccess"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import LeadFormsTable from "./LeadFormsTable.vue";
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BListGroupItem,
} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  components: {
    LeadFormsTable,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    BListGroupItem,
    draggable,
  },
  computed: {
    ...mapGetters("admin", ["getLeadForms", "getUsers"]),
    getLeadFormsList() {
      let leadForms = [];
      for (const [key, leadForm] of Object.entries(this.getLeadForms.answer)) {
        let email = "";
        if (this.getUsers.answer[leadForm.userId])
          email = this.getUsers.answer[leadForm.userId].email;
        leadForms.push({ ...leadForm, email, id: key });
      }
      if (this.$route.query.user) {
        leadForms = leadForms.filter(
          (leadForm) => leadForm.userId === this.$route.query.user
        );
      }
      return leadForms;
    },
    usersList() {
      const users = [{ value: null, text: "Все" }];
      for (const [key, user] of Object.entries(this.getUsers.answer)) {
        users.push({ value: key, text: user.email });
      }
      return users;
    },
  },
  data() {
    return {
      createFormName: "",
      createFormTitle: "",
      createFormSuccess: "Спасибо, Ваша заявка принята",
      questionsList: [
        {
          id: this.randomKey(),
          type: "name",
          title: "Имя",
          enabled: true,
        },
        {
          id: this.randomKey(),
          type: "phone",
          title: "Телефон",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "email",
          title: "E-mail",
          enabled: true,
        },
        {
          id: this.randomKey(),
          type: "string",
          title: "Произвольный вопрос",
          enabled: false,
        },
      ],
      questionOptionsList: [
        { value: "name", text: "Имя" },
        { value: "phone", text: "Телефон" },
        { value: "email", text: "E-mail" },
        { value: "string", text: "Свой вопрос" },
      ],
      editFormId: "",
      filters: {},
    };
  },
  methods: {
    ...mapActions("admin", ["requestLeadForms", "requestUsers", "editForm"]),
    editLeadForm(data) {
      this.questionsList = [...data.questions];
      this.createFormName = data.name;
      this.createFormTitle = data.title;
      this.createFormSuccess = data.sucсessText;
      this.editFormId = data.id;
      this.modalType = "editModal";
      this.$refs["modal-create"].show();
    },
    randomKey() {
      let r = (Math.random() + 1).toString(36).substring(2);
      return r;
    },
    resetModal() {
      this.questionsList = [
        {
          id: this.randomKey(),
          type: "name",
          title: "Имя",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "phone",
          title: "Телефон",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "email",
          title: "E-mail",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "string",
          title: "Произвольный вопрос",
          enabled: false,
        },
      ];
      this.createFormName = "";
      this.createFormSuccess = "Спасибо, Ваша заявка принята";
    },
    editItem(item) {
      this.editForm(item);
    },
    filterByUser() {
      this.$router.replace({ query: { user: this.filters.user } });
    },
  },
  created() {
    this.requestUsers();
    this.requestLeadForms();
    if (this.$route.query.user) {
      this.filters.user = this.$route.query.user;
    }
  },
};
</script>
